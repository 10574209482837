import React from "react";
import PropTypes from "prop-types";

const Wordmark = ({ className, color }) => {
  return (
    <svg className={className} viewBox="0 0 100 48" fill="none">
      <path
        d="M12.2041 37.1074C11.0828 37.677 10.2415 38.0315 9.0807 38.4753C8.79793 36.7219 8.46479 34.678 8.21945 32.7111C9.12768 32.2011 10.2295 31.6413 10.9834 31.2582C12.9166 30.2761 14.0813 30.4626 14.759 31.7964C15.859 33.9614 15.007 35.6833 12.2041 37.1074ZM13.8343 43.332C13.7349 42.9448 13.574 42.0537 13.5224 41.5692C12.7977 41.9618 11.8891 42.4233 11.0192 42.8652C10.2073 43.2777 9.94463 43.1437 9.77426 42.2818C9.66373 41.6812 9.51299 40.858 9.33247 39.9282C10.3773 39.5433 11.6249 39.031 12.9201 38.3731C16.8249 36.3892 17.9121 33.5506 16.5371 30.8444C15.2604 28.3314 13.0212 28.4718 10.4696 29.7682C9.96703 30.0236 8.64302 30.7206 7.2512 31.4277C5.78205 32.1741 4.13894 33.0089 3.66517 33.2252C3.81405 33.7576 3.89542 34.3486 3.95747 34.9494C4.25692 34.7729 4.85619 34.4685 5.37811 34.2033C6.07403 33.8498 6.46282 33.9927 6.62309 34.7869C7.04368 36.8593 7.7397 41.0536 7.98725 43.3599C8.09933 44.2028 7.85847 44.4468 7.23038 44.7903C6.86308 44.9768 6.30251 45.2617 5.88673 45.4486C6.00675 46.0199 6.05928 46.6498 6.12132 47.2506C7.03908 46.7114 12.8285 43.7458 13.8343 43.332Z"
        fill={color}
      />
      <path
        d="M29.2479 34.5139C28.1748 34.9251 27.4109 34.5677 26.9222 33.2922C26.0687 31.065 26.8142 27.4356 29.1426 26.5434C30.1347 26.1632 31.1027 26.3263 32.2972 27.2618C32.1137 32.1155 30.7462 33.9398 29.2479 34.5139ZM33.0595 32.7051C33.5638 34.0211 34.6017 34.3665 35.8367 33.8932C37.1325 33.3966 37.8686 32.3484 38.5305 30.864C38.0186 30.619 37.58 30.3226 37.1306 29.9376C36.7771 31.2573 36.3624 31.8109 35.7549 32.0437C35.2892 32.2221 34.9532 32.0723 34.7438 31.5256C34.2937 30.3513 34.1079 27.0788 34.4211 22.9881C33.4945 23.2968 32.8763 23.4408 32.2426 23.5443C32.2587 24.374 32.2593 25.1633 32.2849 25.8965C31.2011 24.8257 29.8504 24.3913 28.2712 24.9965C24.9709 26.2611 23.7742 31.0154 25.0698 34.3966C25.8224 36.3607 27.3687 37.1845 29.2113 36.4785C30.9727 35.8035 32.1469 34.2622 32.563 31.4093L32.7249 31.3472C32.8253 31.9124 32.9276 32.3608 33.0595 32.7051Z"
        fill={color}
      />
      <path
        d="M48.1231 14.7848C48.3925 15.7901 49.1656 16.4135 50.4851 16.0599C51.6998 15.7344 52.1623 14.7799 51.8929 13.7746C51.6292 12.7903 50.7458 12.174 49.531 12.4995C48.3163 12.825 47.8594 13.8004 48.1231 14.7848ZM48.8097 27.1489C49.2306 28.7197 50.4338 29.2727 52.2978 28.7733C54.1199 28.2851 55.9479 26.8973 57.2167 23.8412C56.683 23.6925 56.11 23.397 55.6264 23.1001C54.7772 25.4602 53.4769 26.6391 52.2412 26.9702C51.3406 27.2115 50.9157 26.9662 50.7137 26.2123C50.3264 24.7671 51.8477 21.3964 51.4156 19.7838C51.0452 18.4015 49.8603 18.0007 48.4989 18.3655C46.5511 18.8874 45.0837 20.4478 43.1694 23.2729C43.6531 23.5698 44.057 23.8207 44.612 24.3005C46.0783 21.7302 47.2279 20.659 48.317 20.3671C48.9243 20.2044 49.3058 20.3716 49.4517 20.9161C49.8109 22.2565 48.2541 25.0755 48.8097 27.1489Z"
        fill={color}
      />
      <path
        d="M73.1176 24.1919C73.1576 23.6147 73.2943 22.9563 73.4346 22.3193C72.924 22.4223 71.7745 22.6491 70.554 22.8431C69.7831 22.9657 69.6157 22.4654 69.7346 21.8317C70.5083 17.9983 72.1939 15.2055 74.2495 14.8787C75.0205 14.7561 75.8278 15.001 76.1995 15.8201C75.965 15.7257 75.7192 15.6989 75.4194 15.7465C74.4986 15.8929 73.955 16.6161 74.0946 17.4941C74.2239 18.3077 74.8906 18.7726 75.8114 18.6262C77.1819 18.4083 77.6945 17.0754 77.4595 15.5978C77.2213 14.0989 76.1872 12.7045 74.26 13.0109C72.397 13.307 70.984 14.9148 70.0079 18.1656L69.8366 18.1929C69.9584 17.1636 69.9857 16.3689 69.904 15.855C69.6861 14.4845 68.76 13.9072 67.4967 14.1081C66.2547 14.3055 65.1284 15.231 63.9656 16.7552C64.3379 17.0253 64.7024 17.3845 65.1516 17.862C65.884 16.6697 66.6239 15.9374 67.352 15.8216C67.8873 15.7365 68.1344 16.0485 68.2298 16.6481C68.3796 17.5903 68.154 19.6241 67.5313 22.3356C67.336 23.179 67.0635 23.398 66.464 23.4933C65.4789 23.6499 64.6832 23.7545 64.3551 23.7627C64.322 24.3827 64.1818 25.0198 64.0381 25.6354C65.1629 25.3907 71.8654 24.3251 73.1176 24.1919Z"
        fill={color}
      />
      <path
        d="M92.3644 13.0904C93.9882 13.0035 94.8833 13.4983 94.9343 14.451C95.0167 15.9883 92.6733 16.8304 87.2867 17.2058C87.6482 15.0368 89.528 13.2424 92.3644 13.0904ZM91.2389 20.8591C88.8139 20.989 87.4502 20.2586 87.2458 18.4674C93.8552 18.2218 97.0995 16.7669 96.9592 14.1471C96.871 12.5016 95.435 11.2323 92.5337 11.3877C87.5755 11.6533 84.9997 15.0484 85.1842 18.491C85.3234 21.0892 87.1917 22.7261 90.9373 22.5254C93.1025 22.4095 95.2433 21.8388 97.2917 19.9485C96.9483 19.6195 96.6016 19.2255 96.1805 18.6618C94.787 20.213 92.8195 20.7745 91.2389 20.8591Z"
        fill={color}
      />
    </svg>
  );
};

Wordmark.defaultProps = {
  className: ``,
  color: `white`
};

Wordmark.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};

export default Wordmark;
