/* eslint-disable react/prop-types */

import React, { useContext } from "react";
import Img from "gatsby-image";
import { AppContext } from "~context/AppContext";

const CartlistItem = ({ cartItem, cartItemIndex }) => {
  const {
    decreaseQuantityByCartIndex,
    increaseQuantityByCartIndex,
    removeFromCartByIndex
  } = useContext(AppContext);

  return (
    <article className="nav__cart__item relative flex overflow-hidden mb-v1 xs:mb-5 bg-off-white">
      <div className="w-1/4 relative block">
        <div className="square">
          {(cartItem?.image?.childImageSharp && (
            <figure className="w-full h-full absolute transform-center overflow-hidden">
              <Img
                className="w-full h-full relative block object-cover"
                fluid={cartItem.image.childImageSharp.fluid}
                alt={cartItem.title}
              />
            </figure>
          )) || (
            <img
              className="w-full absolute transform-center"
              src={cartItem.image}
              alt={cartItem.title}
            />
          )}
        </div>
      </div>

      <div className="w-3/4 relative flex flex-col justify-between pl-v1 xs:pl-2">
        <div>
          <header className="flex justify-between">
            <h2 className="pr-v1 xs:pr-2 b2">{cartItem.title}</h2>
            <h3 className="b2">{cartItem.price}</h3>
          </header>

          <h4 className="mt-1 caption" style={{ fontSize: `10px` }}>
            {`${cartItem.pack}, `}
            {cartItem.selectedOptions.map(
              (selectedOption, selectedOptionIndex) => {
                return (
                  <span key={selectedOption.name}>
                    {selectedOptionIndex > 0 ? `, ` : ``}
                    {selectedOption.value}
                  </span>
                );
              }
            )}
          </h4>
        </div>

        <div className="w-full relative flex justify-between mt-4">
          <div className="flex">
            <h4 className="caption">Qty: </h4>

            <div className="flex">
              <button
                type="button"
                className="px-v1 caption"
                onClick={() => decreaseQuantityByCartIndex(cartItemIndex)}
              >
                -
              </button>

              <h4 className="caption">{cartItem.quantity}</h4>

              <button
                type="button"
                className="px-v1 caption"
                onClick={() => increaseQuantityByCartIndex(cartItemIndex)}
              >
                +
              </button>
            </div>
          </div>

          <button
            type="button"
            className="hover-underline caption"
            onClick={() => removeFromCartByIndex(cartItemIndex)}
          >
            Remove
          </button>
        </div>
      </div>
    </article>
  );
};

export default CartlistItem;
