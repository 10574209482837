/* eslint-disable react/prop-types */

import React, { useContext, useEffect, useState } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { AppContext } from "~context/AppContext.jsx";
import { DocumentContext } from "~context/DocumentContext";
import Button from "~components/Button";
import CartlistItem from "~components/CartlistItem.jsx";
import MobileNav from "~components/MobileNav.jsx";
import ScreenHeight from "~components/ScreenHeight";
import Cross from "~components/svg/Cross";
import { useKeyPress } from "~utils/hooks";
import { getPriceByCurrency } from "~utils/shopify";

const Nav = () => {
  const data = useStaticQuery(graphql`
    query Nav {
      allSanityProduct {
        edges {
          node {
            title
            handle
            packSize
            image {
              asset {
                fluid(maxWidth: 512) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }

      allShopifyProduct {
        edges {
          node {
            id
            title
            description
            handle
            images {
              originalSrc
            }
            productType
            vendor
            variants {
              id
              sku
              title
              image {
                originalSrc
              }
              price
              presentmentPrices {
                edges {
                  node {
                    price {
                      amount
                      currencyCode
                    }
                  }
                }
              }
              selectedOptions {
                name
                value
              }
            }
          }
        }
      }

      allShopifyAdminProduct {
        edges {
          node {
            products {
              variants {
                alternative_id
                inventory_item_id
                sku
              }
            }
          }
        }
      }

      sanityFreePaire {
        handle
      }

      sanitySettings {
        cookieVersion
        inventoryEnabled
      }
    }
  `);

  const {
    activeCurrency,
    activeCurrencySymbol,
    cart,
    cartActive,
    setCartActive,
    menuActive,
    setMenuActive,
    inventoryFetched,
    setInventoryFetched,
    //
    checkout,
    cookieVersion,
    setCookieVersion,
    setInventoryEnabled,
    refreshInventoryCache,
    freeItem,
    setFreeItem,
    freeItemValid,
    notificationActive
  } = useContext(AppContext);
  const { device, scrollTop } = useContext(DocumentContext);

  const [freeMessage, setFreeMessage] = useState(true);
  const [freeItemOptions, setFreeItemOptions] = useState([]);

  //

  const navCart = [];
  const products = [];

  let cartCount = freeItem?.variant?.id ? 1 : 0;
  let cartTotal = 0;

  //--------------------------------------------------------------------------------
  // onLoad

  useEffect(() => {
    if (
      parseInt(data?.cookieVersion) > 0 &&
      cookieVersion !== data?.cookieVersion
    ) {
      setCookieVersion(data.cookieVersion);
    }

    if (typeof data?.inventoryEnabled !== `undefined`) {
      setInventoryEnabled(data.inventoryEnabled);
    }
  }, []);

  //--------------------------------------------------------------------------------
  // menu open

  const close = () => {
    setCartActive(false);
    setMenuActive(false);
  };

  const escKeyPressed = useKeyPress(`Escape`);

  useEffect(() => {
    if (escKeyPressed) {
      close();
    }
  }, [escKeyPressed]);

  useEffect(() => {
    // keeps the cart 'sticky' if you've got something free in it
    if (!freeItem) {
      close();
    }
  }, [scrollTop]);

  //--------------------------------------------------------------------------------
  // free item

  const selectFreeItem = value => {
    const freeItemClone = JSON.parse(JSON.stringify(freeItem));

    let newVariant;

    freeItemClone.variants.forEach(variant => {
      if (newVariant) {
        return;
      }

      variant.selectedOptions.forEach(option => {
        if (newVariant) {
          return;
        }

        if (option.value === value) {
          newVariant = variant;
        }
      });
    });

    if (newVariant) {
      freeItemClone.variant = newVariant;

      setFreeItem(freeItemClone);
    }
  };

  useEffect(() => {
    if (freeItemValid && data?.sanityFreePaire?.handle) {
      let defaultFreeItem;
      let allFreeItemOptions;

      data.allShopifyProduct.edges.forEach(({ node }) => {
        if (defaultFreeItem) {
          return;
        }

        if (node.handle === data.sanityFreePaire.handle) {
          defaultFreeItem = node;

          // eslint-disable-next-line prefer-destructuring
          defaultFreeItem.variant = defaultFreeItem.variants[0];

          defaultFreeItem.variants.forEach(variant => {
            variant.selectedOptions.forEach(option => {
              if (!freeItemOptions.includes(option)) {
                freeItemOptions.push(option);
              }
            });
          });
        }
      });

      if (defaultFreeItem) {
        setFreeItem(defaultFreeItem);
      }
      if (allFreeItemOptions) {
        setFreeItemOptions(allFreeItemOptions);
      }
    }
  }, [freeItemValid]);

  //--------------------------------------------------------------------------------
  // cartdata

  if (data?.allShopifyProduct?.edges?.[0]) {
    data.allShopifyProduct.edges.forEach(({ node }) => {
      products.push(node);
    });
  }

  if (!inventoryFetched) {
    // setTimeout fixes the static query/render lifecycle warning
    setTimeout(() => {
      setInventoryFetched(true);
      refreshInventoryCache(products, data.allShopifyAdminProduct);
    });
  }

  cart.forEach(cartItem => {
    products.forEach(productItem => {
      productItem.variants.forEach(productItemVariant => {
        if (productItemVariant.id === cartItem.variantId) {
          const lineItemCost =
            getPriceByCurrency(productItemVariant, activeCurrency) *
            cartItem.quantity;

          cartCount += cartItem.quantity;
          cartTotal += lineItemCost;

          let packSize = null;
          let cartImage = null;

          data.allSanityProduct.edges.forEach(({ node }) => {
            if (node.handle === productItem.handle) {
              if (!cartImage && productItem?.image?.asset?.fluid) {
                cartImage = productItem.frontmatter.image;
              }

              ({ packSize } = node);
            }
          });

          navCart.push({
            id: cartItem.variantId,
            image: productItemVariant?.image?.originalSrc,
            pack: packSize,
            quantity: cartItem.quantity,
            price: `${activeCurrencySymbol}${parseFloat(lineItemCost).toFixed(
              2
            )}`,
            slug: productItem.slug,
            selectedOptions: productItemVariant.selectedOptions,
            title: productItem.title,
            variantTitle: productItemVariant.title
          });
        }
      });
    });
  });

  const canCheckout =
    (!freeItem && cartCount > 0) || (freeItem && cartCount > 1);

  //

  return (
    <ScreenHeight
      className={`nav ${notificationActive ? `notification-active` : ``} ${
        cartActive ? `cart-active` : ``
      } ${
        menuActive ? `menu-active` : ``
      } w-screen fixed flex items-center justify-between z-40 xs:z-30 pointer-events-none transition-transform`}
    >
      <MobileNav menuActive={menuActive} close={close} />

      <div
        className={`nav__cart ${
          cartActive ? `pointer-events-auto` : ``
        } transition-transform h-full absolute top-0 right-0 z-20 bg-hit-pink text-black`}
      >
        <section className="w-full h-full relative flex flex-col items-stretch pt-v2">
          {device === `mobile` && freeItem && freeMessage && (
            <article className="w-full relative bg-picasso xs:mb-3 xs:px-4 xs:py-4">
              <h3 className="b2 xs:mb-2">You’ve received a free Paire!</h3>

              <p className="caption">
                A free Paire has been added to your bag. Make sure to choose
                your size and add another item to the bag before you check out!
              </p>

              <button
                type="button"
                className="w-1/12 absolute top-0 right-0 flex justify-center mt-2"
                onClick={() => setFreeMessage(false)}
              >
                <Cross className="w-1/2" color="black" />
              </button>
            </article>
          )}

          <div className="w-full relative mb-v1 xs:mb-3 xs:mt-10 pr-v2 xs:pr-4 pl-v2 xs:pl-4">
            <div className="relative flex items-center justify-between border-b-black">
              <h2 className="b1">Bag</h2>
              <span className="caption">
                ({cartCount}){` `}
                {cartCount === 1 ? `item` : `items`}
              </span>

              {device && device === `desktop` && (
                <button
                  type="button"
                  className="nav__cart__close relative block"
                  onClick={close}
                >
                  <Cross className="w-full h-full" color="black" />
                </button>
              )}
            </div>
          </div>

          {(cartActive && (navCart?.[0] || freeItemValid) && (
            <>
              <ul className="nav__cart__list h-full relative overflow-y-scroll pb-v2 xs:pb-8 pr-v2 xs:pr-4 pl-v2 xs:pl-4">
                {freeItemValid && freeItem && (
                  <li>
                    <article className="nav__cart__item relative flex overflow-hidden mb-v1 xs:mb-5 bg-off-white">
                      <div className="w-1/4 relative block">
                        <div className="square">
                          {(freeItem?.images[0]?.childImageSharp && (
                            <figure className="w-full h-full absolute transform-center overflow-hidden">
                              <Img
                                className="w-full h-full relative block object-cover"
                                fluid={freeItem.images[0].childImageSharp.fluid}
                                alt={freeItem.title}
                              />
                            </figure>
                          )) || (
                            <img
                              className="w-full absolute transform-center"
                              src={freeItem.images[0].originalSrc}
                              alt={freeItem.title}
                            />
                          )}
                        </div>
                      </div>

                      <div className="w-3/4 relative flex flex-col justify-between pl-v1 xs:pl-2">
                        <div>
                          <div className="flex justify-between">
                            <h2 className="pr-v1 xs:pr-2 b2">
                              {freeItem.title}
                            </h2>
                            <h2 className="b2">{freeItem.price}</h2>
                          </div>

                          <h2 className="caption">
                            1 Paire,{` `}
                            {freeItem.variant.selectedOptions.map(
                              (selectedOption, selectedOptionIndex) => {
                                return (
                                  <span key={selectedOption.name}>
                                    {selectedOptionIndex > 0 ? `, ` : ``}
                                    {selectedOption.value}
                                  </span>
                                );
                              }
                            )}
                          </h2>
                        </div>

                        <div className="w-full relative mt-4">
                          {/* {!freeItemSize && (
                            <p className="caption text-hit-pink">
                              Please choose your size
                            </p>
                          )} */}

                          <div className="relative flex items-center">
                            <h4 className="caption mr-2">Size: </h4>

                            <ul className="flex items-center">
                              {freeItemOptions.map(option => (
                                <li key={option.value}>
                                  <button
                                    type="button"
                                    className="w-6 h-6 relative block mr-1"
                                    onClick={() => {
                                      selectFreeItem(option.value);
                                    }}
                                  >
                                    <div className="w-full h-full relative flex items-center justify-center caption">
                                      <span
                                        className={
                                          freeItem?.variant
                                            ?.selectedOptions?.[0]?.value ===
                                          option.value
                                            ? `underline`
                                            : ``
                                        }
                                      >
                                        {option.value}
                                      </span>
                                    </div>
                                  </button>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </article>
                  </li>
                )}

                {navCart.map((cartItem, cartItemIndex) => {
                  const key = `${cartItem.id}-${cartItem.pack}`;

                  return (
                    <li key={key}>
                      <CartlistItem
                        cartItem={cartItem}
                        cartItemIndex={cartItemIndex}
                      />
                    </li>
                  );
                })}
              </ul>

              <div className="nav__cart__total w-full relative z-10 bg-off-white">
                <div className="w-full relative flex justify-between">
                  <h3 className="mb-4 b2">Subtotal:</h3>

                  <h3 className="mb-4 b1 font-bold">
                    ${parseFloat(cartTotal).toFixed(2)}
                    <span className="caption font-normal ml-1">Aus</span>
                  </h3>
                </div>

                <Button
                  className={`${
                    canCheckout ? `` : `opacity-50 pointer-events-none`
                  } w-full relative block`}
                  color="black"
                  onClick={() => {
                    if (canCheckout) {
                      checkout(products);
                    }
                  }}
                >
                  <span className="f-button">Checkout</span>
                </Button>
              </div>
            </>
          )) || (
            <>
              <h3 className="animation-appear-slow animation-delay-2 mt-v3 pr-v2 pl-v2 b1 text-center text-black">
                Your cart is empty
              </h3>
            </>
          )}
        </section>

        {device === `desktop` && cartActive && freeItem && (
          <div
            style={{
              left: `-100%`
            }}
            className="w-full h-full absolute flex flex-col justify-center top-0 -left-full text-center bg-picasso mb-3 px-5 py-5"
          >
            <h3 className="f3 mb-v2">You’ve received a free Paire!</h3>

            <p className="caption mb-v2">
              A free Paire has been added to your bag. Make sure to choose your
              size and add another item to the bag before you check out!
            </p>

            <Link to="/products">
              <Button
                className="w-full"
                color="white"
                onClick={() => setCartActive(false)}
              >
                <span className="f-button">Shop Now</span>
              </Button>
            </Link>
          </div>
        )}
      </div>
    </ScreenHeight>
  );
};

export default Nav;
