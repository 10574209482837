/* eslint-disable react/prop-types */

import React, { useContext } from "react";
import { graphql, StaticQuery } from "gatsby";
import { AppContext } from "~context/AppContext";
import { DocumentContext } from "~context/DocumentContext";
import Cross from "~components/svg/Cross";

const query = graphql`
  query Notification {
    sanityNotification {
      text
    }
  }
`;

const Notification = () => {
  const { notificationActive, setNotificationActive } = useContext(AppContext);
  const { device } = useContext(DocumentContext);

  //

  return (
    <StaticQuery
      query={query}
      render={data => {
        const notificationText = data?.sanityNotification?.text;

        if (!notificationText && notificationActive) {
          setNotificationActive(false);
        }

        return (
          <div
            className={`notification ${
              notificationActive ? `active` : ``
            } w-full h-8 fixed top-0 right-0 left-0 z-30 flex items-center justify-center overflow-hidden bg-hit-pink text-black text-center`}
          >
            <button
              type="button"
              onClick={() => setNotificationActive(false)}
              className={`transition-opacity ${
                notificationActive ? `` : `opacity-0`
              } w-full h-full relative block`}
            >
              <span
                className={`${device === `desktop` ? `b2` : `caption`} block`}
              >
                {notificationText}
              </span>
            </button>

            <div className="w-8 h-8 absolute top-0 right-0 bottom-0 pointer-events-none flex items-center justify-center">
              <Cross className="w-6" />
            </div>
          </div>
        );
      }}
    />
  );
};

export default Notification;
