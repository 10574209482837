import React from "react";
import PropTypes from "prop-types";

const Button = ({
  children,
  className,
  color,
  noborder,
  onClick,
  transparent
}) => (
  <button
    type="button"
    className={`button button--${color} ${
      transparent ? `button--transparent` : ``
    } ${className} ${noborder ? `no-border underline` : ``} relative block`}
    onClick={onClick}
  >
    {children}
  </button>
);

Button.defaultProps = {
  color: ``,
  className: ``,
  noborder: false,
  onClick: () => {},
  transparent: false
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  className: PropTypes.string,
  noborder: PropTypes.bool,
  onClick: PropTypes.func,
  transparent: PropTypes.bool
};

export default Button;
