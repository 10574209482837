/* eslint-disable camelcase */
import React, { createContext, useEffect, useState } from "react";
import getSymbolFromCurrency from "currency-symbol-map";
import PropTypes from "prop-types";
import { useCookies } from "react-cookie";
import { globalHistory } from "@reach/router";
import {
  fbqTrackCheckout,
  fbqTrackProduct,
  pushProductEvent
} from "~utils/analytics";
import { fancyError, fancyWarning } from "~utils/helpers";
import {
  getCheckoutURL,
  getInventoryLevelsByIds,
  getInventoryIdByVariantSku
} from "~utils/shopify";

export const AppContext = createContext({});

const AppProvider = ({ children }) => {
  const [activeCurrency, setActiveCurrency] = useState(`AUD`);
  const [activeCurrencySymbol, setActiveCurrencySymbol] = useState(null);
  const [cart, setCart] = useState([]);
  const [cartActive, setCartActive] = useState(false);
  const [cookies, setCookie] = useCookies(null);
  const [cookiesAccepted, setCookiesAccepted] = useState(true);
  const [cookieMessageActive, setCookieMessageActive] = useState(false);
  const [cookieVersion, setCookieVersion] = useState(0);
  const [inventoryCache, setInventoryCache] = useState(null);
  const [inventoryEnabled, setInventoryEnabled] = useState(false);
  const [inventoryFetched, setInventoryFetched] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const [notificationActive, setNotificationActive] = useState(true);
  const [pathname, setPathname] = useState(null);
  const [headerStyle, setHeaderStyle] = useState(`light`);
  const [freeItem, setFreeItem] = useState(null);
  const [freeItemValid, setFreeItemValid] = useState(false);

  //

  const setDefaultCurrency = () => {
    let defaultCurrency;

    switch (process.env.GATSBY_REGION_CODE.toLowerCase()) {
      case `us`:
        defaultCurrency = `USD`;

        break;

      case `au`:
      default:
        defaultCurrency = `AUD`;
        break;
    }

    setActiveCurrency(defaultCurrency);
    setActiveCurrencySymbol(getSymbolFromCurrency(defaultCurrency));
  };

  //

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(
      `%c+`,
      `font-size: 1px; padding: 0 50px; line-height: 38px; background: url(https://www.loveandmoney.agency/uploads/logo.gif); background-size: 100px 38px; background-repeat: no-repeat; color: transparent;`
    );

    if (window) {
      setPathname(window.location.pathname);
    }

    setDefaultCurrency();

    //
    // [cookie] cart

    if (!cookies?.accepted) {
      setCookieMessageActive(true);
    } else {
      setCookiesAccepted(true);

      // if the region cart is set
      if (
        cookies?.[`v${cookieVersion}:${process.env.GATSBY_REGION_CODE}_cart`]
      ) {
        const parsedCart =
          cookies[`v${cookieVersion}:${process.env.GATSBY_REGION_CODE}_cart`];

        let valid =
          Array.isArray(
            cookies[`v${cookieVersion}:${process.env.GATSBY_REGION_CODE}_cart`]
          ) &&
          cookies?.[
            `v${cookieVersion}:${process.env.GATSBY_REGION_CODE}_cart`
          ]?.[0];

        if (valid) {
          parsedCart.forEach(cookieCartItem => {
            if (!valid) {
              return;
            }

            if (
              typeof cookieCartItem === `undefined` ||
              cookieCartItem === null ||
              cookieCartItem === false ||
              cookieCartItem === `` ||
              !cookieCartItem?.variantId ||
              !cookieCartItem?.quantity
            ) {
              valid = false;
            }
          });
        }

        if (!valid || process.env.GATSBY_RESET_COOKIES) {
          fancyWarning(`Resetting cart data`);
          setCookie(
            [`v${cookieVersion}:${process.env.GATSBY_REGION_CODE}_cart`],
            [],
            {
              path: `/`
            }
          );
          setCart([]);
        } else {
          setCart(parsedCart);
        }
      }

      if (cookies?.currency) {
        setActiveCurrency(cookies.currency);
      }
    }

    //

    return globalHistory.listen(({ location }) => {
      setPathname(location.pathname);
    });
  }, []);

  // [cookie] acceptance

  useEffect(() => {
    if (cookiesAccepted) {
      setCookie(`accepted`, true, { path: `/` });
    }
  }, [cookiesAccepted]);

  // [cookie] currency

  useEffect(() => {
    if (cookiesAccepted) {
      setCookie(
        `v${cookieVersion}:${process.env.GATSBY_REGION_CODE}_currency`,
        activeCurrency,
        {
          path: `/`
        }
      );
    }

    setActiveCurrencySymbol(getSymbolFromCurrency(activeCurrency));
  }, [activeCurrency, cookiesAccepted]);

  // [pathname] checking for heading

  useEffect(() => {
    if (pathname) {
      if (
        pathname.includes(`/contact`) ||
        pathname.includes(`/products`) ||
        pathname.includes(`/faqs`) ||
        pathname.includes(`/shipping`) ||
        pathname.includes(`/terms`) ||
        pathname.includes(`/404`)
      ) {
        setHeaderStyle(`dark`);
      } else {
        setHeaderStyle(`light`);
      }
    }
  }, [pathname]);

  //

  const addToCart = (productWithVariant, quantity = 1) => {
    if (!productWithVariant?.variant) {
      return;
    }

    let existingCartPosition = null;

    const cartClone = JSON.parse(JSON.stringify(cart));

    cartClone.forEach((cartItem, cartIndex) => {
      if (existingCartPosition !== null) {
        return;
      }

      if (cartItem.variantId === productWithVariant.variant.id) {
        existingCartPosition = cartIndex;
      }
    });

    if (existingCartPosition === null) {
      cartClone.push({
        quantity,
        pack: productWithVariant.variant.pack,
        variantId: productWithVariant.variant.id
      });
    } else {
      cartClone[existingCartPosition].quantity += quantity;
    }

    setCartActive(true);
    setCart(cartClone);

    if (cookiesAccepted) {
      setCookie(
        `v${cookieVersion}:${process.env.GATSBY_REGION_CODE}_cart`,
        cartClone,
        {
          path: `/`
        }
      );

      pushProductEvent(
        productWithVariant,
        activeCurrency,
        `addToCart`,
        quantity
      );

      fbqTrackProduct(`AddToCart`, productWithVariant, activeCurrency);
    }
  };

  const checkout = products => {
    if (
      !process.env.GATSBY_SHOPIFY_STORE ||
      process.env.GATSBY_SHOPIFY_STORE === ``
    ) {
      fancyError(`Shopify environment variables have not been defined.`);

      return;
    }

    setCartActive(false);

    // facebook
    if (cookiesAccepted && products?.[0]) {
      fbqTrackCheckout(cart, products, activeCurrency);
    }

    const cartForCheckout = JSON.parse(JSON.stringify(cart));

    if (freeItem?.variant?.id) {
      cartForCheckout.push({
        variantId: freeItem.variant.id,
        quantity: 1
      });
    }

    getCheckoutURL(cartForCheckout, activeCurrency).then(response => {
      response.json().then(({ data }) => {
        if (data?.checkoutCreate?.checkout?.webUrl) {
          let { webUrl } = data.checkoutCreate.checkout;

          //
          // prod check-in routing goes here

          if (webUrl.includes(`getpaire.myshopify.com`)) {
            webUrl = webUrl.replace(
              `getpaire.myshopify.com`,
              `orders.paire.com.au`
            );
          }

          window.location.href = webUrl;
        }
      });
    });
  };

  const decreaseQuantityByCartIndex = cartIndex => {
    if (!cart?.[cartIndex]) {
      return;
    }

    const cartClone = JSON.parse(JSON.stringify(cart));

    if (cartClone[cartIndex].quantity <= 1) {
      cartClone.splice(cartIndex, 1);
    } else {
      cartClone[cartIndex].quantity -= 1;
    }

    setCart(cartClone);

    if (cookiesAccepted) {
      setCookie(
        [`v${cookieVersion}:${process.env.GATSBY_REGION_CODE}_cart`],
        cartClone,
        {
          path: `/`
        }
      );

      pushProductEvent(cartClone[cartIndex], activeCurrency, `removeFromCart`);
    }
  };

  const increaseQuantityByCartIndex = cartIndex => {
    if (!cart?.[cartIndex]) {
      return;
    }

    const cartClone = JSON.parse(JSON.stringify(cart));

    cartClone[cartIndex].quantity += 1;

    setCart(cartClone);

    if (cookiesAccepted) {
      setCookie(
        [`v${cookieVersion}:${process.env.GATSBY_REGION_CODE}_cart`],
        cartClone,
        {
          path: `/`
        }
      );

      pushProductEvent(cartClone[cartIndex], activeCurrency, `addToCart`);
    }
  };

  const removeFromCartByIndex = cartIndex => {
    if (!cart?.[cartIndex]) {
      return;
    }

    const cartClone = JSON.parse(JSON.stringify(cart));

    cartClone.splice(cartIndex, 1);

    setCart(cartClone);

    if (cookiesAccepted) {
      setCookie(
        [`v${cookieVersion}:${process.env.GATSBY_REGION_CODE}_cart`],
        cartClone,
        {
          path: `/`
        }
      );

      pushProductEvent(
        cart[cartIndex],
        activeCurrency,
        `removeFromCart`,
        cart[cartIndex].quantity
      );
    }
  };

  const refreshInventoryCache = async (products, adminProducts) => {
    if (
      typeof window === `undefined` ||
      typeof fetch === `undefined` ||
      window.location.href.includes(`localhost`)
    ) {
      setInventoryCache({});
      return;
    }

    const variantsByInventoryId = {};

    products.forEach(product => {
      product.variants.forEach(variant => {
        const inventoryId = getInventoryIdByVariantSku(
          adminProducts.edges,
          variant.sku
        );

        if (inventoryId && inventoryId !== ``) {
          variantsByInventoryId[inventoryId] = variant;
        }
      });
    });

    //

    const saveInventoryBatch = async response => {
      return new Promise((resolve, reject) => {
        const inventoryBatch = {};

        response.json().then(inventory => {
          if (!inventory?.inventory_levels?.[0]) {
            reject(new Error(`Couldn't find inventory levels`));
            return;
          }

          inventory.inventory_levels.forEach(inventoryLevel => {
            const variant =
              variantsByInventoryId[inventoryLevel.inventory_item_id];

            if (typeof inventoryLevel.available === `undefined`) {
              inventoryBatch[variant.id] = 0;

              return;
            }

            inventoryBatch[variant.id] = inventoryLevel.available;
          });

          resolve(inventoryBatch);
        });
      });
    };

    getInventoryLevelsByIds(Object.keys(variantsByInventoryId)).then(
      async responses => {
        let newInventoryCache = {};

        await Promise.all(
          responses.map(async response => {
            const batchedInventory = await saveInventoryBatch(
              response,
              variantsByInventoryId
            );

            newInventoryCache = Object.assign(
              newInventoryCache,
              batchedInventory
            );
          })
        );

        setInventoryCache(newInventoryCache);
      }
    );
  };

  useEffect(() => {
    if (inventoryCache) {
      setInventoryFetched(true);
    }
  }, [inventoryCache]);

  //

  useEffect(() => {
    const freeCookie = `v${cookieVersion}:${process.env.GATSBY_REGION_CODE}_free`;

    if (freeItemValid) {
      setCookie(freeCookie, true, {
        path: `/`
      });
    }

    if (cookies[freeCookie]) {
      setFreeItemValid(true);
    }

    if (freeItemValid && !cartActive && !cookies[freeCookie]) {
      setCartActive(true);
    }
  }, [freeItemValid]);

  //

  return (
    <AppContext.Provider
      value={{
        cart,
        setCart,
        cartActive,
        setCartActive,
        activeCurrencySymbol,
        setActiveCurrencySymbol,
        activeCurrency,
        setActiveCurrency,
        cookiesAccepted,
        setCookiesAccepted,
        cookieMessageActive,
        setCookieMessageActive,
        cookieVersion,
        setCookieVersion,
        inventoryCache,
        setInventoryCache,
        inventoryEnabled,
        setInventoryEnabled,
        inventoryFetched,
        setInventoryFetched,
        menuActive,
        setMenuActive,
        notificationActive,
        setNotificationActive,
        pathname,
        headerStyle,
        refreshInventoryCache,
        //
        addToCart,
        checkout,
        decreaseQuantityByCartIndex,
        increaseQuantityByCartIndex,
        removeFromCartByIndex,
        freeItem,
        setFreeItem,
        freeItemValid,
        setFreeItemValid,
        cookies
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AppProvider;
