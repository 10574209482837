/* eslint-disable react/prop-types */

import React, { useContext, useEffect, useState } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { AppContext } from "~context/AppContext.jsx";
import { DocumentContext } from "~context/DocumentContext";
import CartIcon from "~components/svg/CartIcon";
import Wordmark from "~components/svg/Wordmark";
import Cross from "~components/svg/Cross";

const Header = () => {
  const { sanityHeader } = useStaticQuery(graphql`
    query Header {
      sanityHeader {
        links {
          target
          text
        }
      }
    }
  `);

  const {
    cart,
    cartActive,
    setCartActive,
    freeItem,
    headerStyle,
    menuActive,
    setMenuActive,
    notificationActive
  } = useContext(AppContext);
  const { device } = useContext(DocumentContext);

  const [headerColor, setHeaderColor] = useState({
    text: ``,
    logo: ``,
    icon: ``,
    bg: ``
  });

  useEffect(() => {
    if (headerStyle === `dark`) {
      setHeaderColor({
        ...headerColor,
        text: `text-black`,
        logo: `black`,
        icon: `bg-black`
      });
    } else {
      setHeaderColor({
        ...headerColor,
        text: `text-off-white`,
        logo: `white`,
        icon: `bg-white`
      });
    }
  }, [headerStyle]);

  useEffect(() => {
    // Cart
    if (cartActive) {
      setMenuActive(false);
      setHeaderColor({
        text: `text-black`,
        logo: `black`,
        icon: `bg-black`,
        bg: `bg-hit-pink`
      });
    } else if (menuActive) {
      setCartActive(false);
      setHeaderColor({
        text: `text-black`,
        logo: `black`,
        icon: `bg-black`,
        bg: `bg-white`
      });
    } else if (headerStyle !== `dark`) {
      setHeaderColor({
        text: `text-off-white`,
        logo: `white`,
        icon: `bg-white`,
        bg: `bg-transparent`
      });
    } else {
      setHeaderColor({ ...headerColor, bg: `bg-transparent` });
    }
  }, [menuActive, cartActive]);

  const toggleCart = () => {
    setCartActive(!cartActive);
  };

  //

  let cartCount = freeItem?.variant?.id ? 1 : 0;

  cart.forEach(cartItem => {
    cartCount += cartItem.quantity;
  });

  //

  return (
    <header
      className={`header ${
        notificationActive ? `notification-active` : ``
      } transition-transform w-full fixed top-0 right-0 left-0 z-30 text-white`}
    >
      {device && device === `desktop` && (
        <nav
          className={`animation-appear-down animation-delay-2  grid items-center ${headerColor.text}`}
        >
          <Link to="/" className="grid-end-1 relative">
            <Wordmark
              className="header__logo relative block"
              color={headerColor.logo}
            />
          </Link>

          {sanityHeader?.links?.[0] &&
            sanityHeader.links.map(link => {
              const key = link.text;

              return (
                <Link
                  key={key}
                  to={link.target}
                  className="h-full grid-end-1 relative flex items-center hover-underline"
                >
                  <span className="f-menu">{link.text}</span>
                </Link>
              );
            })}

          {/* // TODO : change pl-8 */}
          <div className="h-full grid-end-1 grid-start-12 pl-8 flex items-center justify-end">
            <button type="button" onClick={toggleCart}>
              <span className="f-menu italic">Bag({cartCount})</span>
            </button>
          </div>
        </nav>
      )}

      {device && device !== `desktop` && (
        <nav
          className={`header transition-background ${headerColor.bg} ${
            menuActive ? `border-b-black` : ``
          }`}
        >
          <div
            className={`header__overlay grid items-center ${headerColor.text}`}
          >
            <div className="grid-end-4 block items-center justify-start">
              <button
                type="button"
                className="w-6 relative block"
                onClick={() => {
                  setMenuActive(!menuActive);
                  setCartActive(false);
                }}
              >
                <div
                  className={`header__menu__icon flex flex-col gap-col-1 ${
                    menuActive ? `justify-center` : `justify-between`
                  } w-full h-full`}
                >
                  {menuActive ? (
                    <div
                      className={`header__menu__line header__menu__line--0 w-full block ${headerColor.icon}`}
                    ></div>
                  ) : (
                    <>
                      <div
                        className={`header__menu__line header__menu__line--0 w-full block ${headerColor.icon}`}
                      ></div>
                      <div
                        className={`header__menu__line header__menu__line--0 w-full block ${headerColor.icon}`}
                      ></div>
                      <div
                        className={`header__menu__line header__menu__line--0 w-full block ${headerColor.icon}`}
                      ></div>
                    </>
                  )}
                </div>
              </button>
            </div>

            <Link
              to="/"
              className="grid-end-4 relative flex items-center justify-center"
            >
              <Wordmark
                className="w-32 relative block"
                color={headerColor.logo}
              />
            </Link>

            <button
              type="button"
              className="grid-end-4 flex items-center justify-end"
              onClick={() => {
                setCartActive(!cartActive);
                setMenuActive(false);
              }}
            >
              {cartActive ? (
                <Cross className="w-8" color="black" />
              ) : (
                <CartIcon className="w-8" color={headerColor.logo} />
              )}
            </button>
          </div>
        </nav>
      )}
    </header>
  );
};

export default Header;
