import React from "react";
import { Link } from "gatsby";
import MenuTypeFeature from "~components/svg/MenuTypeFeature";

const MobileNav = ({ close, menuActive }) => {
  return (
    <nav
      className={`nav__menu ${
        menuActive ? `pointer-events-auto` : `pointer-events-none`
      } transition-transform h-full absolute top-0 right-0 z-20 bg-off-white text-black px-5 py-5 overflow-hidden`}
    >
      <ul className="flex flex-col items-center my-20 gap-row-10">
        <li>
          <Link to="/" className="block b1" onClick={close}>
            Home
          </Link>
        </li>

        <li>
          <Link to="/products" className="block b1" onClick={close}>
            Shop
          </Link>
        </li>

        <li>
          <Link to="/about" className="block b1" onClick={close}>
            About
          </Link>
        </li>
      </ul>

      <MenuTypeFeature className="menu-type-feature w-full" />

      <h3 className="mt-12 b2 text-center mx-4">© COPYRIGHT PAIRE 2020</h3>
    </nav>
  );
};

export default MobileNav;
