import React from "react";

const MenuTypeFeature = ({ className }) => {
  return (
    <svg
      width="499"
      height="154"
      viewBox="0 0 499 154"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0)">
        <path d="M28.4051 41.6996C27.0281 43.8059 27.2958 45.3884 29.1343 46.6069C31.1479 47.9318 33.8581 47.4493 34.5105 46.4232C34.7105 46.131 34.6434 45.8682 34.0244 45.4628C32.8883 44.7147 31.801 44.0547 31.328 43.763C31.8159 43.4561 32.2724 43.1019 32.6908 42.7054C33.5805 43.3485 38.6493 46.7128 39.5494 47.2001C39.0506 47.5126 38.5888 47.8806 38.1728 48.2971C37.8688 48.054 37.5527 47.8266 37.2256 47.616C36.6719 47.2514 36.4383 47.3368 35.842 47.825C34.6711 48.6902 33.5456 49.6152 32.4698 50.5964C31.9862 50.2357 31.4821 49.9033 30.9603 49.6008C31.4222 49.2687 32.1153 48.8326 32.8405 48.2042L32.7343 48.078C31.8944 48.4338 30.9814 48.5843 30.0706 48.517C29.1599 48.4497 28.2776 48.1666 27.4966 47.691C24.8144 45.9255 24.7242 43.3079 26.4266 40.6529C28.7774 37.0589 34.0097 34.4864 37.9031 37.0887C38.7502 37.5569 39.3815 38.3381 39.6617 39.265C39.9419 40.1918 39.8487 41.1907 39.4021 42.0475L39.5281 42.0922C40.5218 41.1782 41.4883 40.3166 41.7887 40.0158C42.4355 40.4931 43.1616 40.9718 43.6216 41.25C42.3256 42.0922 39.8981 44.0386 38.8844 44.8835C38.4539 44.5715 37.9628 44.2557 37.5236 43.9937C37.6664 43.8141 37.761 43.6706 37.888 43.4854C39.2433 41.4157 38.695 39.7248 37.0993 38.6721C34.2239 36.776 30.2037 38.9724 28.4051 41.6996Z" fill="black"/>
        <path d="M47.6074 50.4497C47.9215 49.9588 47.7801 49.5544 47.2365 49.2111C46.2541 48.5794 44.6511 48.4539 41.6891 49.1221C41.6659 48.4984 41.5875 47.8778 41.4548 47.2677C44.9294 46.7281 47.1194 46.7959 48.8623 47.9098C50.0853 48.6888 50.6103 49.8669 49.8196 51.11C48.8964 52.554 45.2954 53.7308 44.4747 55.0341C44.0443 55.7052 44.1524 56.2041 44.9574 56.7109C46.0706 57.4243 47.863 57.5904 50.2388 56.5993C50.3409 57.1965 50.5244 57.7771 50.784 58.3248C47.59 59.4775 45.2768 59.0294 43.6353 58.0047C41.9939 56.9799 41.5428 55.642 42.4443 54.2345C43.6294 52.4228 46.8412 51.6483 47.6074 50.4497ZM54.0587 44.6859C54.2856 44.8106 54.4847 44.9803 54.6438 45.1847C54.8029 45.3891 54.9186 45.6237 54.9839 45.8743C55.0492 46.1249 55.0626 46.386 55.0234 46.6416C54.9841 46.8973 54.8931 47.142 54.7557 47.3608C54.6184 47.5796 54.4377 47.7679 54.2248 47.9139C54.0119 48.06 53.7713 48.1609 53.5178 48.2102C53.2642 48.2596 53.0031 48.2565 52.7505 48.2011C52.4979 48.1458 52.2591 48.0392 52.0489 47.8881C51.8094 47.7754 51.5979 47.6107 51.4298 47.406C51.2617 47.2013 51.1411 46.9618 51.0769 46.7049C51.0128 46.448 51.0066 46.1802 51.0588 45.9209C51.1111 45.6616 51.2204 45.4174 51.379 45.2061C51.5099 44.9907 51.6838 44.8047 51.8899 44.6597C52.096 44.5147 52.3298 44.4139 52.5768 44.3635C52.8238 44.3132 53.0787 44.3143 53.3255 44.3669C53.5723 44.4195 53.8059 44.5224 54.0115 44.6692L54.0587 44.6859Z" fill="black"/>
        <path d="M58.8628 56.262C58.4106 55.9865 57.7223 55.955 56.2579 56.5958C56.2629 56.0656 56.1986 55.5367 56.0666 55.0227C57.8519 54.4514 59.1872 54.4555 60.2509 55.1071C60.51 55.2291 60.7382 55.4081 60.9186 55.6308C61.099 55.8535 61.2267 56.114 61.2924 56.3929C61.358 56.6717 61.3598 56.9617 61.2975 57.2409C61.2353 57.5202 61.1107 57.7815 60.933 58.0053C59.7567 59.9437 57.1888 61.1053 56.3296 62.5185C55.9284 63.1821 56.144 63.7278 56.9803 64.2456C59.3796 65.7072 64.1295 64.5842 65.8779 62.0252L65.9276 61.8835C65.6973 61.9752 65.448 62.0097 65.2012 61.9839C64.9543 61.958 64.7172 61.8727 64.5102 61.7351C64.3337 61.6425 64.1789 61.5133 64.0559 61.3563C63.933 61.1992 63.8448 61.0178 63.7972 60.8242C63.7497 60.6306 63.7438 60.4292 63.7799 60.2334C63.8161 60.0375 63.8936 59.8518 64.0071 59.6884C64.1513 59.4578 64.3415 59.2594 64.5657 59.1057C64.7899 58.9519 65.0435 58.846 65.3106 58.7946C65.5777 58.7431 65.8527 58.7472 66.1184 58.8066C66.3842 58.866 66.6351 58.9794 66.8556 59.1398C68.1518 59.9359 68.298 61.3602 67.3871 62.8702C65.3446 66.1661 59.3406 67.9868 55.5183 65.5603C53.8565 64.5195 53.399 63.2505 54.315 61.7512C55.3884 59.98 58.142 58.592 58.9333 57.2964C59.2678 56.8483 59.2122 56.5275 58.8628 56.262Z" fill="black"/>
        <path d="M75.2852 67.3815C75.5678 66.8794 75.4158 66.4801 74.8352 66.1414C73.8254 65.562 72.2161 65.5051 69.2812 66.298C69.249 65.697 69.1622 65.1 69.0219 64.5145C72.4668 63.8581 74.6667 63.7966 76.453 64.8373C77.7111 65.5667 78.2814 66.7166 77.551 67.9899C76.6986 69.4591 73.1424 70.8111 72.3741 72.1418C71.9696 72.8398 72.0991 73.3286 72.9308 73.8094C74.0712 74.4706 75.8728 74.5601 78.2053 73.4651C78.3301 74.0551 78.5351 74.6255 78.8145 75.1602C75.6998 76.465 73.3372 76.1323 71.6607 75.1571C69.9842 74.1819 69.4743 72.8852 70.3105 71.4368C71.389 69.5252 74.5686 68.6154 75.2852 67.3815ZM81.4481 61.3294C81.664 61.4548 81.8532 61.6215 82.005 61.8199C82.1567 62.0183 82.2681 62.2446 82.3326 62.4858C82.3971 62.727 82.4136 62.9785 82.3811 63.2258C82.3486 63.4732 82.2677 63.7115 82.1431 63.9273C82.0185 64.1431 81.8526 64.3321 81.6549 64.4834C81.4572 64.6348 81.2315 64.7456 80.9907 64.8095C80.75 64.8735 80.4989 64.8893 80.2517 64.856C80.0046 64.8227 79.7663 64.7411 79.5504 64.6157C79.3069 64.5137 79.089 64.3588 78.9124 64.1622C78.7358 63.9656 78.605 63.7323 78.5295 63.4791C78.4539 63.226 78.4355 62.9593 78.4756 62.6985C78.5156 62.4377 78.6132 62.1892 78.7611 61.9711C78.8834 61.7402 79.0534 61.5381 79.2598 61.3781C79.4662 61.2182 79.7044 61.1042 79.9584 61.0435C80.2125 60.9828 80.4767 60.9769 80.7335 61.0262C80.9903 61.0755 81.2339 61.1789 81.4481 61.3294Z" fill="black"/>
        <path d="M86.133 72.3148C85.6883 72.0686 84.9941 72.0793 83.5525 72.7813C83.5312 72.2563 83.4465 71.7357 83.3001 71.2308C85.0343 70.5528 86.3811 70.499 87.4827 71.0932C87.7427 71.2004 87.975 71.3656 88.1618 71.5762C88.3487 71.7868 88.4851 72.0372 88.5607 72.3083C88.6363 72.5795 88.6491 72.8641 88.5982 73.1406C88.5472 73.417 88.4337 73.678 88.2665 73.9035C87.9517 74.4265 87.5792 74.9122 87.156 75.3517L87.345 75.4186C90.3109 73.6277 92.4282 73.7497 94.0347 74.6203C95.3874 75.3566 95.8992 76.521 95.1405 77.9255C94.0065 80.0206 90.8058 80.8162 89.9787 82.3648C89.9127 82.451 89.8677 82.5514 89.8471 82.6582C89.8266 82.765 89.8312 82.8752 89.8605 82.98C89.8898 83.0849 89.943 83.1815 90.0159 83.2624C90.0889 83.3432 90.1796 83.4061 90.2808 83.4459C90.8904 83.7771 91.6353 83.5719 92.6782 83.0473C92.6565 83.6135 92.6979 84.1806 92.8016 84.738C92.1775 85.0339 91.4947 85.1859 90.8033 85.1829C90.1119 85.1799 89.4296 85.022 88.8063 84.7206C88.5352 84.6108 88.2912 84.4432 88.0911 84.2295C87.891 84.0158 87.7397 83.7612 87.6477 83.4833C87.5557 83.2055 87.5251 82.911 87.5583 82.6205C87.5914 82.33 87.6874 82.0504 87.8395 81.8011C88.949 79.7506 91.9923 78.8992 92.8217 77.3691C93.1969 76.6786 92.9439 76.1639 92.274 75.8024C90.7147 74.9486 87.4182 75.4623 81.7995 80.4213L81.5023 80.6879C80.9108 80.2169 80.2851 79.7908 79.6304 79.4133C83.2613 76.6362 85.5642 74.7165 86.304 73.3406C86.5857 72.8915 86.5282 72.5258 86.133 72.3148Z" fill="black"/>
        <path d="M102.04 90.1878C102.562 89.5846 103.215 88.8332 103.954 88.0148L103.781 87.9533C102.981 88.4615 102.062 88.7528 101.115 88.7988C100.168 88.8448 99.2242 88.644 98.3763 88.2159C96.3078 87.1726 95.7799 85.5242 96.7176 83.6604C98.127 80.8569 101.897 78.8193 104.81 80.3305C105.567 80.6805 106.216 81.2294 106.686 81.9188C107.157 82.6081 107.432 83.4119 107.482 84.2444C107.579 84.1458 107.699 84.0556 107.812 83.9362C108.26 83.4928 108.726 83.0471 109.166 82.6009C109.732 83.0496 110.328 83.4591 110.949 83.8264C108.215 86.1602 105.679 88.718 103.368 91.4734C101.7 93.326 99.5068 95.1079 95.9642 93.3207C93.4899 92.0715 92.9898 90.495 93.5842 89.3065C93.6744 89.0808 93.8128 88.8776 93.9898 88.7113C94.1668 88.545 94.378 88.4195 94.6087 88.3435C94.8395 88.2676 95.0841 88.2431 95.3256 88.2717C95.567 88.3004 95.7995 88.3815 96.0067 88.5093C96.192 88.5774 96.3612 88.6836 96.5031 88.8211C96.6451 88.9586 96.7568 89.1243 96.831 89.3075C96.9052 89.4907 96.9402 89.6873 96.9338 89.8847C96.9274 90.082 96.8798 90.2757 96.7939 90.4533C96.7065 90.6672 96.5658 90.855 96.3851 90.9987C96.2045 91.1423 95.9899 91.2372 95.762 91.2741C96.0164 91.5117 96.3051 91.7096 96.6183 91.8611C98.4889 92.7368 100.122 92.2709 102.04 90.1878ZM106.52 84.9926C106.42 84.3218 106.156 83.6858 105.751 83.1417C105.345 82.5977 104.811 82.1625 104.197 81.8754C102.241 80.8897 99.6022 82.354 98.7177 84.1924C98.1561 85.3128 98.3922 86.2289 99.7695 86.9209C101.67 87.887 103.616 87.39 106.512 84.9898L106.52 84.9926Z" fill="black"/>
        <path d="M132.888 103.616C133.337 102.943 133.889 102.128 134.518 101.218L134.353 101.159C133.615 101.764 132.733 102.165 131.792 102.324C130.852 102.482 129.885 102.393 128.988 102.065C126.807 101.292 126.099 99.6767 126.795 97.7448C127.837 94.7756 131.37 92.2815 134.419 93.4068C135.211 93.6656 135.92 94.133 136.471 94.76C137.022 95.387 137.395 96.1505 137.551 96.9703C137.634 96.8582 137.749 96.7573 137.838 96.6295C138.227 96.1295 138.638 95.6377 139.05 95.146C139.666 95.5207 140.306 95.8541 140.966 96.1442C138.541 98.7958 136.339 101.644 134.382 104.659C132.947 106.701 130.982 108.768 127.224 107.391C124.618 106.467 123.938 104.924 124.369 103.722C124.431 103.487 124.543 103.269 124.698 103.082C124.853 102.896 125.047 102.746 125.267 102.642C125.486 102.539 125.725 102.484 125.968 102.483C126.211 102.482 126.451 102.533 126.672 102.634C126.865 102.678 127.047 102.761 127.205 102.88C127.364 102.999 127.496 103.149 127.592 103.322C127.688 103.495 127.747 103.687 127.765 103.884C127.782 104.081 127.758 104.28 127.693 104.467C127.631 104.69 127.514 104.894 127.352 105.059C127.19 105.225 126.989 105.347 126.767 105.414C127.049 105.617 127.361 105.776 127.692 105.883C129.682 106.571 131.25 105.887 132.888 103.616ZM136.676 97.8734C136.495 97.2177 136.153 96.617 135.683 96.1255C135.212 95.634 134.627 95.2672 133.981 95.0582C131.925 94.3296 129.513 96.1313 128.828 98.0317C128.416 99.2052 128.761 100.089 130.207 100.611C132.259 101.374 134.111 100.64 136.699 97.9082L136.676 97.8734Z" fill="black"/>
        <path d="M155.245 104.42C154.453 107.53 150.857 110.284 146.119 109.03C142.718 108.125 141.542 105.928 142.225 103.301C143.04 100.199 146.581 97.4253 151.393 98.7238C154.736 99.5903 155.999 101.791 155.245 104.42ZM144.351 103.727C143.853 105.623 144.791 106.885 146.775 107.412C148.075 107.767 149.46 107.613 150.647 106.981C151.835 106.349 152.736 105.286 153.166 104.01C153.664 102.115 152.628 100.853 150.686 100.359C149.397 99.9759 148.011 100.114 146.826 100.744C145.641 101.374 144.752 102.445 144.351 103.727V103.727Z" fill="black"/>
        <path d="M171.669 107.37C171.175 110.542 167.845 113.621 163.012 112.811C159.543 112.228 158.167 110.146 158.606 107.459C159.132 104.298 162.396 101.205 167.312 102.053C170.745 102.615 172.137 104.676 171.669 107.37ZM160.756 107.69C160.44 109.623 161.49 110.793 163.514 111.13C164.841 111.366 166.207 111.087 167.332 110.348C168.457 109.609 169.256 108.468 169.566 107.156C169.882 105.223 168.742 104.057 166.763 103.745C165.443 103.483 164.074 103.75 162.952 104.488C161.829 105.225 161.04 106.375 160.756 107.69V107.69Z" fill="black"/>
        <path d="M186.02 101.682C185.209 101.697 184.412 101.885 183.683 102.236C183.565 101.626 183.379 101.031 183.127 100.462C184.289 100.072 185.508 99.8812 186.734 99.8989C188.206 99.9954 189.136 100.75 189.036 102.247C188.888 104.559 185.215 110.404 185.078 112.561C185.034 113.165 185.288 113.45 185.823 113.489C186.509 113.529 187.156 113.174 188.084 112.094C188.321 112.615 188.627 113.101 188.992 113.541C187.713 114.664 186.541 115.329 185.067 115.24C184.772 115.25 184.48 115.193 184.21 115.075C183.94 114.957 183.699 114.779 183.506 114.557C183.313 114.334 183.172 114.07 183.093 113.786C183.014 113.502 182.999 113.204 183.05 112.914C183.096 112.444 183.179 111.979 183.299 111.523L183.12 111.504C181.531 113.987 179.726 114.915 177.773 114.78C175.819 114.645 174.659 113.207 174.799 111.069C175.034 107.424 178.192 103.575 181.817 103.815C182.612 103.846 183.377 104.131 183.999 104.629C184.622 105.127 185.068 105.811 185.273 106.581C185.943 105.306 186.459 103.955 186.811 102.556C186.827 102.445 186.819 102.331 186.786 102.223C186.754 102.115 186.697 102.016 186.622 101.932C186.546 101.849 186.453 101.783 186.348 101.74C186.244 101.696 186.132 101.677 186.02 101.682ZM184.655 107.762C183.905 106.318 183.118 105.711 182.01 105.637C179.421 105.445 177.204 108.432 177.041 110.837C176.958 112.233 177.512 112.899 178.688 112.979C180.328 113.1 182.681 111.747 184.655 107.762Z" fill="black"/>
        <path d="M215.507 105.167C218.134 104.849 220.83 104.511 221.641 104.347C221.513 104.99 221.45 105.644 221.451 106.299C220.671 106.351 218.103 106.627 215.542 106.933C215.591 109.014 215.61 112.113 215.592 112.541C215.588 116.916 213.986 118.704 211.491 118.998C209.986 119.173 208.909 118.632 208.756 117.328C208.714 117.107 208.72 116.878 208.774 116.659C208.827 116.44 208.928 116.235 209.068 116.059C209.208 115.883 209.385 115.739 209.586 115.637C209.787 115.535 210.007 115.478 210.233 115.469C210.457 115.433 210.686 115.445 210.905 115.506C211.125 115.566 211.329 115.673 211.503 115.819C211.678 115.965 211.819 116.146 211.918 116.351C212.017 116.557 212.07 116.78 212.075 117.008C212.074 117.037 212.079 117.066 212.089 117.093C213.039 116.482 213.621 115.253 213.637 112.937C213.639 112.15 213.601 109.257 213.571 107.173C211.656 107.38 209.993 107.624 209.443 107.73C209.565 107.086 209.629 106.433 209.632 105.777C210.15 105.766 211.713 105.629 213.557 105.397C213.555 105.175 213.558 105.016 213.546 104.923C213.548 100.858 215.341 98.8535 218.346 98.4927C220.119 98.2883 221.337 98.8796 221.486 100.146C221.52 100.371 221.507 100.6 221.449 100.819C221.392 101.038 221.29 101.244 221.15 101.422C221.01 101.6 220.835 101.748 220.637 101.857C220.438 101.965 220.219 102.032 219.993 102.053C219.773 102.102 219.546 102.103 219.326 102.056C219.106 102.009 218.898 101.915 218.717 101.78C218.537 101.645 218.387 101.473 218.279 101.275C218.171 101.078 218.106 100.859 218.09 100.634C218.076 100.502 218.075 100.368 218.089 100.235C216.371 100.663 215.442 102.096 215.508 105.061L215.507 105.167Z" fill="black"/>
        <path d="M237.011 106.823C237.421 107.239 237.865 107.621 238.336 107.966C236.834 109.884 234.668 111.169 232.264 111.569C228.459 112.319 226.34 110.939 225.807 108.324C225.097 104.848 227.206 101.009 232.211 99.9674C235.137 99.3752 236.786 100.455 237.123 102.116C237.664 104.761 234.616 106.735 227.911 107.954C228.389 109.744 229.882 110.282 232.329 109.795C234.221 109.461 235.904 108.393 237.011 106.823V106.823ZM227.763 106.661C233.179 105.482 235.426 104.277 235.113 102.723C234.917 101.768 233.925 101.416 232.294 101.724C229.445 102.299 227.804 104.4 227.763 106.661V106.661Z" fill="black"/>
        <path d="M253.303 102.602C253.738 102.994 254.207 103.347 254.704 103.656C253.327 105.657 251.253 107.069 248.887 107.616C245.175 108.629 242.946 107.387 242.241 104.808C241.298 101.392 243.142 97.423 248.057 96.0836C250.917 95.3264 252.65 96.2682 253.104 97.8729C253.82 100.474 250.882 102.638 244.31 104.31C244.908 106.063 246.434 106.507 248.836 105.844C250.694 105.393 252.298 104.229 253.303 102.602V102.602ZM244.066 103.064C249.388 101.523 251.553 100.174 251.134 98.6443C250.883 97.6697 249.863 97.3968 248.256 97.8368C245.446 98.6029 243.954 100.81 244.066 103.064Z" fill="black"/>
        <path d="M265.213 100.81C266.72 100.326 268.167 98.7496 269.231 95.4166C269.778 95.7046 270.349 95.9427 270.938 96.1279C269.359 99.9955 267.777 101.905 265.277 102.719C263.266 103.405 261.849 102.903 261.273 101.14C260.307 98.1412 262.252 92.349 261.631 90.4639C261.477 89.9933 261.139 89.8205 260.569 90.0078C259.569 90.3351 258.601 91.4266 257.044 93.7435C256.6 93.3295 256.122 92.9528 255.616 92.6173C257.401 90.0537 259.087 88.4288 260.777 87.8768C261.046 87.7488 261.341 87.6822 261.639 87.6818C261.937 87.6813 262.232 87.747 262.502 87.8741C262.772 88.0013 263.011 88.1867 263.202 88.417C263.392 88.6474 263.529 88.917 263.604 89.2064C264.459 91.8121 262.518 97.8712 263.24 100.075C263.494 100.838 264.136 101.181 265.213 100.81Z" fill="black"/>
        <path d="M279.24 92.6516C276.066 93.3686 274.452 93.0976 273.822 91.3864C273.109 89.4595 274.719 87.4445 278.206 86.1652C281.692 84.8859 283.383 85.7953 283.843 87.0567C283.926 87.2506 283.967 87.4603 283.962 87.6712C283.957 87.8822 283.907 88.0894 283.814 88.2787C283.722 88.4681 283.589 88.635 283.426 88.768C283.263 88.901 283.073 88.997 282.869 89.0494C282.703 89.1416 282.52 89.1974 282.332 89.2132C282.143 89.229 281.953 89.2044 281.774 89.141C281.595 89.0777 281.432 88.977 281.295 88.8457C281.158 88.7143 281.05 88.5554 280.979 88.3794C280.891 88.1733 280.863 87.9463 280.9 87.7254C280.937 87.5045 281.036 87.299 281.186 87.1333C280.313 87.2276 279.456 87.4325 278.634 87.7431C276.017 88.6926 275.279 89.7331 275.592 90.6323C275.905 91.5315 276.58 91.5759 279.089 90.9865C282.131 90.2934 284.064 90.2084 284.798 92.2044C285.532 94.2003 284.099 96.1632 280.268 97.5417C276.253 99.0145 274.005 98.4302 273.365 96.6977C273.276 96.4909 273.233 96.2674 273.239 96.0426C273.245 95.8178 273.3 95.5972 273.399 95.3958C273.498 95.1944 273.64 95.0171 273.815 94.8762C273.989 94.7352 274.192 94.634 274.41 94.5794C274.572 94.494 274.75 94.4444 274.932 94.434C275.115 94.4235 275.298 94.4525 275.468 94.5189C275.639 94.5852 275.793 94.6875 275.921 94.8187C276.049 94.9499 276.148 95.107 276.21 95.2794C276.321 95.5295 276.333 95.8125 276.242 96.0704C276.152 96.3282 275.966 96.5412 275.723 96.6656C277.114 96.7466 278.506 96.5202 279.799 96.0027C282.312 95.1226 283.397 94.0014 283.025 92.9927C282.684 92.1987 281.886 92.0489 279.24 92.6516Z" fill="black"/>
        <path d="M308.964 76.817C311.435 75.8591 313.952 74.8466 314.692 74.4799C314.739 75.1354 314.842 75.7857 315.002 76.4235C314.261 76.6659 311.849 77.5826 309.443 78.5285C310.025 80.5063 310.813 83.5322 310.907 83.9463C312.001 88.1967 310.9 90.3225 308.556 91.2457C307.151 91.7928 305.974 91.5353 305.492 90.3101C305.395 90.1058 305.343 89.8831 305.34 89.6571C305.336 89.4311 305.382 89.2072 305.473 89.0006C305.564 88.7941 305.698 88.6098 305.867 88.4602C306.036 88.3107 306.235 88.1994 306.451 88.1341C306.659 88.0442 306.884 87.9995 307.111 88.003C307.337 88.0065 307.561 88.0581 307.767 88.1544C307.972 88.2508 308.155 88.3896 308.303 88.5618C308.452 88.734 308.562 88.9356 308.627 89.1533C308.633 89.1826 308.644 89.2106 308.66 89.2359C309.428 88.4098 309.719 87.0776 309.121 84.8193L307.604 79.2498C305.797 79.9558 304.248 80.5848 303.745 80.8317C303.704 80.1771 303.603 79.5274 303.443 78.8909C303.929 78.7441 305.408 78.1879 307.141 77.5438C307.075 77.3258 307.041 77.1719 307.008 77.0893C305.98 73.1812 307.215 70.7487 310.037 69.6494C311.693 68.996 313.022 69.2723 313.483 70.4547C313.572 70.6633 313.618 70.8876 313.617 71.114C313.617 71.3404 313.571 71.5643 313.481 71.772C313.392 71.9797 313.261 72.1669 313.097 72.3222C312.933 72.4776 312.739 72.5979 312.526 72.6757C312.327 72.7788 312.108 72.8375 311.884 72.848C311.66 72.8585 311.436 72.8204 311.227 72.7364C311.019 72.6525 310.831 72.5245 310.676 72.3614C310.521 72.1982 310.403 72.0036 310.33 71.7909C310.286 71.662 310.25 71.5306 310.22 71.3976C308.664 72.2459 308.132 73.8642 308.918 76.7209L308.964 76.817Z" fill="black"/>
        <path d="M330.25 74.3374C330.706 74.683 331.192 74.9884 331.7 75.2501C330.512 77.3614 328.583 78.9553 326.287 79.7248C322.67 81.0993 320.34 80.0431 319.404 77.5414C318.161 74.2225 319.625 70.1013 324.413 68.3181C327.203 67.2795 329.001 68.0851 329.596 69.6687C330.543 72.1918 327.854 74.6304 321.431 76.8873C322.182 78.5796 323.743 78.876 326.077 78.0031C327.896 77.379 329.396 76.0622 330.25 74.3374V74.3374ZM321.094 75.6521C326.234 73.622 328.28 72.0804 327.721 70.5978C327.379 69.6797 326.345 69.4725 324.786 70.0535C322.057 71.0695 320.776 73.4052 321.094 75.6521Z" fill="black"/>
        <path d="M345.823 68.9214C346.274 69.2804 346.753 69.6015 347.257 69.8814C346.013 71.9519 344.049 73.488 341.74 74.1955C338.094 75.4268 335.791 74.3183 334.937 71.7837C333.798 68.4219 335.404 64.351 340.234 62.7246C343.055 61.7763 344.83 62.645 345.375 64.2461C346.247 66.8048 343.428 69.1351 336.968 71.1932C337.667 72.9109 339.218 73.2573 341.578 72.4642C343.403 71.8865 344.927 70.6145 345.823 68.9214ZM336.619 69.9181C341.838 68.0665 343.923 66.5917 343.414 65.0917C343.104 64.1582 342.06 63.9033 340.497 64.4472C337.753 65.3784 336.38 67.6727 336.627 69.9209L336.619 69.9181Z" fill="black"/>
        <path d="M357.624 67.3126C359.15 66.8976 360.671 65.3911 361.89 62.1218C362.425 62.4284 362.984 62.6917 363.561 62.9091C361.8 66.7125 360.13 68.52 357.601 69.2173C355.523 69.7911 354.167 69.2396 353.67 67.4519C352.84 64.4478 355.064 58.7189 354.529 56.8116C354.397 56.3309 354.07 56.1529 353.492 56.3111C352.509 56.5911 351.452 57.6334 349.791 59.8691C349.372 59.437 348.92 59.0396 348.437 58.6809C350.308 56.2007 352.104 54.6678 353.819 54.1955C354.093 54.0783 354.391 54.024 354.69 54.0364C354.988 54.0489 355.28 54.1278 355.545 54.2674C355.81 54.407 356.04 54.6039 356.22 54.8437C356.399 55.0834 356.523 55.3601 356.583 55.6535C357.311 58.2937 355.086 64.2524 355.7 66.4889C355.887 67.2904 356.504 67.6508 357.624 67.3126Z" fill="black"/>
        <path d="M372.597 60.6144C369.352 60.8279 367.848 60.2947 367.449 58.5059C367.059 56.4901 368.961 54.7554 372.607 54.0553C376.253 53.3551 377.763 54.5016 378.027 55.8174C378.079 56.022 378.087 56.2355 378.049 56.4431C378.011 56.6508 377.929 56.8476 377.808 57.0201C377.686 57.1926 377.529 57.3366 377.347 57.4424C377.165 57.5481 376.962 57.613 376.752 57.6326C376.573 57.6963 376.383 57.7211 376.194 57.7054C376.005 57.6896 375.821 57.6337 375.655 57.5413C375.488 57.4488 375.344 57.3221 375.23 57.1694C375.117 57.0168 375.037 56.8417 374.996 56.656C374.944 56.4396 374.956 56.213 375.029 56.0034C375.103 55.7938 375.235 55.6101 375.411 55.4746C374.536 55.4231 373.657 55.4837 372.798 55.6551C370.063 56.1822 369.151 57.0899 369.31 58.0231C369.469 58.9564 370.143 59.1065 372.692 58.9565C375.805 58.7669 377.734 58.9725 378.152 61.0603C378.57 63.148 376.819 64.8565 372.855 65.6299C368.666 66.4387 366.537 65.5158 366.184 63.6988C366.125 63.4838 366.113 63.2586 366.149 63.0388C366.186 62.819 366.269 62.61 366.395 62.4261C366.52 62.2421 366.683 62.0878 366.874 61.9737C367.065 61.8597 367.279 61.7886 367.5 61.7654C367.672 61.7049 367.856 61.6826 368.037 61.7C368.219 61.7175 368.395 61.7743 368.553 61.8665C368.711 61.9587 368.847 62.0842 368.952 62.2341C369.057 62.3841 369.128 62.555 369.161 62.735C369.228 63.0004 369.191 63.2813 369.056 63.5191C368.921 63.7568 368.7 63.9331 368.438 64.011C369.799 64.3128 371.209 64.3105 372.567 64.0044C375.206 63.4967 376.432 62.6029 376.227 61.5472C376.066 60.7196 375.308 60.4331 372.597 60.6144Z" fill="black"/>
        <path d="M407.308 61.3086C407.585 60.5209 407.798 59.5865 408.116 58.5299L407.934 58.5186C407.424 59.3245 406.714 59.9834 405.872 60.4304C405.03 60.8774 404.086 61.0973 403.132 61.0684C400.818 61.0009 399.645 59.6994 399.691 57.6518C399.768 54.5077 402.3 51.0559 405.572 51.1442C406.406 51.1414 407.225 51.3634 407.944 51.7867C408.664 52.2101 409.256 52.8192 409.66 53.5502C409.708 53.4163 409.755 53.2823 409.81 53.1248C410.02 52.5261 410.23 51.9274 410.498 51.3404C411.199 51.5029 411.91 51.6188 412.626 51.6872C411.152 54.9666 409.958 58.3661 409.058 61.8492C408.331 64.2487 407.11 66.7922 403.154 66.6831C400.396 66.5913 399.264 65.3752 399.293 64.0568C399.282 63.8188 399.321 63.5813 399.408 63.3601C399.496 63.1389 399.63 62.9391 399.801 62.7741C399.972 62.6092 400.177 62.4828 400.401 62.4036C400.625 62.3243 400.864 62.294 401.101 62.3147C401.298 62.298 401.496 62.3227 401.684 62.3874C401.871 62.452 402.043 62.5551 402.188 62.69C402.333 62.8249 402.449 62.9887 402.527 63.1708C402.606 63.3529 402.645 63.5493 402.643 63.7474C402.656 63.9801 402.609 64.212 402.507 64.4207C402.404 64.6295 402.249 64.808 402.057 64.939C402.388 65.0439 402.734 65.0976 403.081 65.0984C405.164 65.1193 406.434 63.975 407.308 61.3086ZM409.143 54.6955C408.767 54.1294 408.257 53.6656 407.657 53.3465C407.058 53.0273 406.389 52.8627 405.711 52.8676C403.528 52.8025 401.8 55.2545 401.754 57.3022C401.72 58.5571 402.327 59.2769 403.86 59.3244C405.969 59.3722 407.547 58.1068 409.112 54.6843L409.143 54.6955Z" fill="black"/>
        <path d="M428.839 58.2112C428.369 61.3917 425.068 64.4897 420.234 63.7324C416.754 63.1804 415.362 61.1195 415.785 58.4269C416.279 55.2548 419.506 52.1396 424.432 52.9382C427.844 53.4572 429.281 55.5164 428.839 58.2112ZM417.967 58.6426C417.666 60.5817 418.73 61.738 420.752 62.0564C422.087 62.2815 423.455 61.9855 424.576 61.2294C425.696 60.4733 426.483 59.3147 426.774 57.9925C427.071 56.0613 425.918 54.9085 423.94 54.6148C422.613 54.3631 421.242 54.6448 420.124 55.3987C419.006 56.1525 418.231 57.318 417.967 58.6426V58.6426Z" fill="black"/>
        <path d="M445.119 63.2107C444.058 66.2345 440.284 68.713 435.657 67.0903C432.342 65.9153 431.336 63.6371 432.23 61.0672C433.291 58.0434 437.023 55.5589 441.731 57.2279C444.988 58.3649 446.042 60.6334 445.119 63.2107ZM434.313 61.6641C433.666 63.5068 434.508 64.8412 436.437 65.525C437.707 65.992 439.107 65.9539 440.347 65.4184C441.588 64.883 442.574 63.891 443.104 62.6468C443.754 60.7962 442.825 59.4575 440.912 58.8057C439.655 58.3169 438.258 58.3428 437.023 58.8777C435.788 59.4127 434.814 60.4137 434.313 61.6641V61.6641Z" fill="black"/>
        <path d="M461.028 64.1666C460.295 63.8175 459.494 63.6363 458.683 63.636C458.845 63.0357 458.94 62.4188 458.965 61.7969C460.188 61.9649 461.374 62.3347 462.477 62.8912C463.75 63.6261 464.263 64.7113 463.525 66.008C462.376 68.0184 456.519 71.6627 455.455 73.5346C455.148 74.0546 455.251 74.4186 455.714 74.6889C456.318 75.0359 457.057 74.9971 458.367 74.4431C458.355 75.0148 458.42 75.5857 458.559 76.1406C456.916 76.5852 455.558 76.6705 454.282 75.9435C454.016 75.8234 453.781 75.6461 453.592 75.4242C453.403 75.2024 453.265 74.9416 453.189 74.6605C453.112 74.3793 453.099 74.0849 453.149 73.7984C453.2 73.5118 453.313 73.2402 453.481 73.0031C453.722 72.5988 453.999 72.2167 454.307 71.8613L454.142 71.8027C451.628 73.338 449.595 73.3789 447.896 72.3954C446.196 71.412 445.789 69.6207 446.869 67.7543C448.683 64.5806 453.208 62.5099 456.352 64.3239C457.048 64.7011 457.606 65.2907 457.946 66.0067C458.285 66.7227 458.388 67.5278 458.238 68.3048C459.401 67.4535 460.458 66.4667 461.387 65.3651C461.463 65.2693 461.515 65.1563 461.539 65.0357C461.562 64.9152 461.556 64.7906 461.521 64.6728C461.486 64.555 461.422 64.4474 461.337 64.3592C461.251 64.2711 461.145 64.205 461.028 64.1666V64.1666ZM457.156 68.9926C457.105 67.3718 456.666 66.4809 455.7 65.9262C453.491 64.6557 450.157 66.334 448.965 68.4176C448.272 69.6332 448.481 70.4775 449.506 71.0624C450.905 71.8948 453.615 71.7399 457.148 69.0162L457.156 68.9926Z" fill="black"/>
        <path d="M463.797 82.3477C463.565 82.2233 463.362 82.0486 463.205 81.8364C463.048 81.6242 462.939 81.3798 462.888 81.1208C462.837 80.8619 462.843 80.5949 462.908 80.3392C462.972 80.0835 463.092 79.8454 463.26 79.6423C463.583 79.2505 464.044 78.9964 464.548 78.9311C465.052 78.8658 465.562 78.9942 465.977 79.2903C466.391 79.5865 466.678 80.0285 466.781 80.5274C466.884 81.0263 466.794 81.545 466.53 81.9792C466.386 82.1968 466.197 82.381 465.976 82.5195C465.755 82.6581 465.507 82.7478 465.249 82.7826C464.99 82.8175 464.727 82.7968 464.477 82.7218C464.226 82.6468 463.995 82.5193 463.797 82.3477V82.3477Z" fill="black"/>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="499" height="154" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default MenuTypeFeature;
